import Vue from 'vue'

export default Vue.extend({
  name: 'DynamicEntry',
  props: {
    entryData: {}
  },
  mounted () {
    if (this.$xp.device.isIPhoneOrPad) {
      /*
      iPhones starting with iOS10 ignore "user-scalable=no" "to improve accessibility"
      In PageContainer.vue, we therefore ignore some events.
      Here we make sure, that i.e. scrolling on menus or pages still does work by marking the event as handled.
      */
      this.$el.addEventListener('touchstart', function (event) {
        if (event.touches.length === 1) {
          event.handled = true
        }
      }, false)
    }
  },
  computed: {
    dynamicComponent () {
      switch (this.entryData.Class) {
        case 'Image':
          return 'ImageEntry'
        case 'Video':
          return 'VideoEntry'
        case 'Menu':
          return 'MenuEntry'
        case 'Html':
          return 'HtmlEntry'
        case 'Text':
          return 'TextEntry'
        case 'Contact':
          return 'ContactEntry'
        case 'Entity':
          return 'EntityEntry'
        case 'Panorama':
        case 'XpPanoramaPage':
          return 'PanoramaEntry'
        case 'AugmentedReality':
          return 'ArEntry'
        case 'ThreeDeeModel':
          return 'ThreeDeeModelEntry'
        default:
          return 'div'
      }
    }
  },
  render (h) {
    // <component :is="dynamicComponent" :entryData="entryData" :localPageData="$attrs.localPageData"></component>
    return h(this.dynamicComponent, { props: { entryData: this.entryData, localPageData: this.$attrs.localPageData } })
  }
})
